import * as React from 'react';
import styled from 'styled-components';

export const CarouselButtonRightWithBackground: React.FC<{
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  id: any;
}> = ({ id, onClick, className }) => {
  return (
    <>
      <CarouselButtonRightBrokerStyle className={className} onClick={onClick}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M8.05556 5L13.0556 10L8.05556 15"
            stroke={`url(#paint0_linear_3_4${id})`}
            strokeWidth="1.38889"
          />
          <defs>
            <linearGradient
              id={`paint0_linear_3_4${id}`}
              x1="9.82881"
              y1="20.125"
              x2="13.0644"
              y2="5.55724"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#834628" />
              <stop offset="0.34375" stopColor="#824528" />
              <stop offset="0.572917" stopColor="#D7A87C" />
              <stop offset="0.791667" stopColor="#9C603F" />
              <stop offset="1" stopColor="#9A5E3D" />
            </linearGradient>
          </defs>
        </svg>
      </CarouselButtonRightBrokerStyle>
    </>
  );
};

const CarouselButtonRightBrokerStyle = styled.button`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 18px;
  margin: auto;
  width: 50px;
  height: 50px;
  background: #2f2f2f;
  border-radius: 50%;
  border: none;
  appearance: none;
  transform: rotate(0deg);
  z-index: 2;
  &:hover {
    cursor: pointer;
  }
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @media all and (min-width: 600px) {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 32px;
    margin: auto;
    width: 50px;
    height: 50px;
    background: #2f2f2f;
    border-radius: 50%;
    border: none;
    appearance: none;
    transform: rotate(0deg);
    z-index: 2;
    &:hover {
      cursor: pointer;
    }
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;
