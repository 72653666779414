import * as React from 'react';

export const useBulletts = ({
  currentIndex,
  currentDirection
}: {
  currentIndex: number;
  currentDirection: string | null;
}) => {
  const [bulletIndex, setBulletIndex] = React.useState(0);
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    setBulletIndex(currentIndex);
    /*if (currentIndex > 2 && currentDirection === 'next') {
      setBulletIndex((prev) => prev + 1);
    } else if (bulletIndex > 0 && currentDirection === 'prev') {
      setBulletIndex((prev) => prev - 1);
    }*/
  }, [currentIndex, currentDirection]);

  React.useEffect(() => {
    const idx = bulletIndex - 2;
    ref.current?.style.setProperty(
      'transform',
      `translateX(-${idx > 0 ? 20 * idx : 0}px)`
    );
  }, [bulletIndex, ref]);

  const getClassName = (
    index: number,
    currentIndex: number,
    bulletIndex: number
  ) => {
    const array: string[] = [];
    if (index === currentIndex) array.push('active');
    if (bulletIndex >= 2 && index < currentIndex - 1) array.push('small');
    if (index > currentIndex + 1) array.push('small');
    if (!array?.length) return;
    return array?.join(' ');
    /*let className = '';
    if (index === currentIndex) {
      className += ' active';
    }

    if (bullettIndex >= 2) {
      if (index < currentIndex - 1) {
        className += ' small';
      }
    }
    if (index > currentIndex + 1) {
      className += ' small';
    }

    return className;*/
  };
  return {
    ref,
    getClassName,
    bulletIndex
  };
};
