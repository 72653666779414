import * as React from 'react';
import styled from 'styled-components';
import { lighten } from 'polished';
import { range } from '../../../utils/helpers/range';
import ReactPaginate from 'react-paginate';

interface IPageOnChangeArgs {
  selected: number;
}

interface IPagination {
  center?: boolean;
  currentPage?: number;
  total?: number;
  itemsPerPage?: number;
  maxPages?: number;
  pageRangeDisplayed?: number;
  pageOnChange?: (args: IPageOnChangeArgs) => void;
  className?: string;
}

export const Pagination: React.FC<IPagination> = ({
  center,
  total,
  currentPage,
  itemsPerPage = 20,
  pageOnChange,
  pageRangeDisplayed,
  className
}) => {
  if (total && total > 0) {
    const pages = range(total / itemsPerPage);
    if (pages.length > 1 && typeof currentPage === 'number') {
      // TODO Fix paginator
      return (
        <StyledPaginationWrapper
          className={
            center
              ? `${className ? className : ''} center`
              : className
              ? className
              : ''
          }
        >
          <ReactPaginate
            initialPage={currentPage}
            previousLabel={'Forrige'}
            nextLabel={'Neste'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={pages.length}
            marginPagesDisplayed={1}
            pageRangeDisplayed={pageRangeDisplayed ? pageRangeDisplayed : 2}
            onPageChange={({ selected }) => {
              if (
                typeof pageOnChange === 'function' &&
                selected !== currentPage
              ) {
                pageOnChange({ selected: selected });
              }
            }}
            containerClassName={'pagination'}
            activeClassName={'active'}
          />
          <MobilePagination>
            <MobilePaginationButtonWrapper>
              <MobilePaginationButton
                onClick={() => {
                  if (
                    typeof pageOnChange === 'function' &&
                    typeof currentPage === 'number'
                  ) {
                    pageOnChange({ selected: currentPage - 1 });
                  }
                }}
                className={currentPage === 0 ? 'disabled' : ''}
              >
                Forrige
              </MobilePaginationButton>
              <MobilePaginationButton
                className={currentPage === pages.length - 1 ? 'disabled' : ''}
                onClick={() => {
                  if (
                    typeof pageOnChange === 'function' &&
                    typeof currentPage === 'number'
                  ) {
                    pageOnChange({ selected: currentPage + 1 });
                  }
                }}
              >
                Neste
              </MobilePaginationButton>
            </MobilePaginationButtonWrapper>
            <MobilePaginationPageNumber>
              Side {currentPage + 1} av {pages.length}
            </MobilePaginationPageNumber>
          </MobilePagination>
        </StyledPaginationWrapper>
      );
    }
    return null;
  }
  return null;
};

const PaginationButtonBackground = lighten(0.07, 'black');
const PaginationButtonHoverBackground = lighten(0.15, 'black');

const MobilePagination = styled.div`
  display: none;
  flex-flow: column;
  width: 100%;

  @media all and (max-width: ${(props) => props.theme.mediaSizes.small}px) {
    display: flex;
  }
`;

const MobilePaginationButtonWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const MobilePaginationPageNumber = styled.div`
  padding-top: 1.25rem;
  width: 100%;
  text-align: center;
  color: ${(props) => props.theme.colors.primary};
`;

const MobilePaginationButton = styled.button`
  padding: 0 0.75rem;
  margin-right: 10px;
  width: calc(100% - 10px);
  line-height: 3.38rem;
  min-height: 3.38rem;
  border-radius: 0;
  border: none;
  outline: none;
  background: rgb(24, 24, 25);
  font-size: 1rem;
  color: white;

  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.primary};
  }

  &:last-child {
    margin-right: 0;
  }

  &.disabled {
    opacity: 0.6;
    pointer-events: none;
  }
`;

const StyledPaginationWrapper = styled.ul`
  display: flex;
  width: 100%;
  padding: 0;
  margin-top: 20px;
  list-style: none;
  user-select: none;

  &.center {
    justify-content: center;
  }

  .pagination {
    @media all and (max-width: ${(props) => props.theme.mediaSizes.small}px) {
      display: none;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: inline-block;
      color: white;
      float: left;
      margin-right: 0.375rem;

      &:last-child {
        margin-right: 0;
      }

      a {
        padding: 0 15px;
        height: 2.25rem;
        line-height: 2.25rem;
        display: block;
        outline: none;
      }

      &:hover {
        cursor: pointer;
        background: ${PaginationButtonHoverBackground};
      }

      &.active {
        background: rgb(232, 200, 147);
        color: black;

        a {
          color: black !important;
        }
      }

      &.previous {
        background: ${PaginationButtonBackground};
        color: white !important;

        a {
          color: white !important;
        }

        &.disabled {
          opacity: 0.6;
          pointer-events: none;
        }
      }

      &.next {
        background: ${PaginationButtonBackground};
        color: white !important;
        margin: 0;

        a {
          color: white !important;
        }

        &.disabled {
          opacity: 0.6;
          pointer-events: none;
        }
      }
    }
  }
`;
