import * as React from 'react';
import styled from 'styled-components';
import { useBulletts } from '../../../utils/hooks/useBullets';

interface IImageCarouselBullets {
  currentIndex: number;
  currentDirection: string | null;
  select?: (index: number) => void;
  images: { url?: string }[];
  className?: string;
  key?: any;
}

export const ImageCarouselBulletsWithAnimation: React.FC<
  IImageCarouselBullets
> = ({ images, select, currentIndex, className, currentDirection }) => {
  const { bulletIndex, ref, getClassName } = useBulletts({
    currentIndex,
    currentDirection
  });

  return (
    <Bullets className={className}>
      <Wrapper ref={ref}>
        {images.map((item: any, index) => {
          return (
            <Bullet
              key={index}
              id={item.url}
              className={getClassName(index, currentIndex, bulletIndex)}
            ></Bullet>
          );
        })}
      </Wrapper>
    </Bullets>
  );
};
const Wrapper = styled.div`
  display: inline-flex;
  transition: transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transform: translateX(0px);
`;
export const Bullets = styled.div`
  position: absolute;
  bottom: 1em;
  max-width: 6em;
  margin: 0 auto;
  overflow: clip;
  left: 0;
  right: 0;
`;
export const Bullet = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-left: 4px;
  margin-right: 4px;
  background-color: #cccccc;
  transition: scale 0.2s ease-in-out;
  &.active {
    background-color: #ffffff;
  }
  &.small {
    scale: 0.6;
  }
`;
