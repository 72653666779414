import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
moment.locale('nb');

const SoldStyle = styled.div`
  position: absolute;
  top: 0;
  right: 18px;
  width: 70px;
  height: 70px;
  background: url('https://reeltimeprod-ahebbuatfggte7f2.z01.azurefd.net/privatmegleren/soldmark.jpg')
    no-repeat;
  background-size: cover;
  background-position: center;
  z-index: 1;
  p {
    position: absolute;
    bottom: 0;
    font-size: 11px;
    display: flex;
    width: 100%;
    justify-content: center;
  }
`;

interface IDate {
  date?: string;
  style?: any;
  className?: any;
}

const StyledPropertySold: React.FC<IDate> = ({ date, style, className }) => {
  return (
    <SoldStyle className={className} style={style}>
      {date ? <p>{moment(date).format('DD.MM.YY')}</p> : null}
    </SoldStyle>
  );
};

export default StyledPropertySold;
